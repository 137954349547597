@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--backgroundColor);
  overflow-x: hidden;
  color: var(--fontPrimaryColor);
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.link-styles {
  text-decoration: none;
  color: #3a3a3a;
}
.btn-link-styles {
  background: white;
  color: black;
}

:root {
  --primaryColor: #4fc0d6;
  --primaryColorDark: #2ca0b8;
  --primaryColorLight: #73bbc9b6;
  --secondaryColor: #f1d4e5;
  --primaryBackgroundColor: #ececec;
  --secondaryBackgroundColor: #dadada;
  --fontPrimaryColor: #535151;
  --fontSecondaryColor: #616161;
  --navbarBg: white;
  --backgroundGradient: radial-gradient(
    circle at 10% 45%,
    #64a1e2 2%,
    #ffffff 25%
  );
  --backgroundGradient2: radial-gradient(
    circle at 90% 45%,
    #64a1e2 2%,
    #ffffff 25%
  );
  --backgroundGradient3: radial-gradient(
    circle at 2% 40%,
    #64a1e2 2%,
    #ffffff 13%
  );
  --backgroundGradient4: radial-gradient(
    circle at 92% 25%,
    #64a1e2 2%,
    #ffffff 10%
  );
  --gradientBackground: -webkit-linear-gradient(
    109.6deg,
    rgb(86, 183, 240) 41.2%,
    rgb(31, 67, 230) 100.2%
  );
  --gradientBackground2: -webkit-linear-gradient(
    109.6deg,
    rgb(86, 96, 240) 41.2%,
    rgb(86, 183, 240) 41.2%,
    rgb(31, 67, 230) 100.2%
  );
  --gradientBackground3: -webkit-linear-gradient(
    109.6deg,
    rgb(255, 255, 255) 41.2%,
    rgb(223, 234, 250) 100.2%
  );
}

::-webkit-scrollbar {
  width: 0.7em;
  background-color: rgb(202, 202, 202);
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(224, 224, 224);
}
body::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: var(--primaryColor);
}
body::-webkit-scrollbar-thumb:hover {
  background-color: rgb(54, 109, 192);
}
